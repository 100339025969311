<template>
  <line-chart style="height: 280px;" class="m-3" :chartdata="data" :options="options"></line-chart>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: ["competenciaIni", "competenciaFim", "trabalho", "percentual"],
  components: {
    "line-chart": () => import("../../../components/line-chart.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    resource: function () {
      return this.apiResource(
        `/v1/timesheet/${this.clientId}/timesheetSetorcompetencia`
      );
    },
    data: function () {
      return {
        datasets: this.relatorio.map((r, i) => {
          return {
            data: this.rangeCompetencia.map((c) => {
              var value = Object.fromEntries(r.competencia)[c];
              return value ? value : 0;
            }),
            backgroundColor: "rgba(53,148,233,0)",
            borderColor: this.$chartColors[i],
            label: r.setor,
          };
        }),
        labels: this.rangeCompetencia.map((c) =>
          this.$options.filters.toMonth(c)
        ),
      };
    },
    rangeCompetencia: function () {
      var mIni = moment(this.competenciaIni);
      var mFim = moment(this.competenciaFim);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competenciaFim);
        mFim = moment(this.competenciaIni);
        diff = mFim.diff(mIni, "months");
      }
      var range = [];
      range.push(mIni.format("YYYY-MM"));
      for (var i = 0; i < diff; i++) {
        range.push(mIni.add(1, "months").format("YYYY-MM"));
      }
      return range;
    },
    options: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: { line: { tension: 0.000001 } },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              //return this.abbreviateNumber(tooltipItem.value * 1);
              //return Math.round(tooltipItem.value);

              return this.$options.filters.toDouble(
                Math.round(tooltipItem.value)
              );
            },
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: (value) => {
                  return this.abbreviateNumber(value);
                },
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
      };
    },
  },
  watch: {
    competenciaIni: function () {
      this.loadDebounce();
    },
    competenciaFim: function () {
      this.loadDebounce();
    },
    clientId: function () {
      this.loadDebounce();
    },
    trabalho: function () {
      this.loadDebounce();
    },
  },
  mounted: function () {
    this.load();
    this.loadDebounce = _.debounce(
      () => {
        this.load();
      },
      100,
      { maxWait: 300 }
    );
  },
  methods: {
    load: function () {
      this.resource
        .get({
          query: `competenciaIni=${this.competenciaIni}&competenciaFim=${this.competenciaFim}&trabalho=${this.trabalho}`,
        })
        .then((response) => {
          if (!response.error) {
            this.relatorio = response[0];
          }
        });
    },
    abbreviateNumber: function (value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", " mil", " mi", " bi", " tri"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 3; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 3) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(2);
        var formatter = new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
        newValue = formatter.format(shortValue) + suffixes[suffixNum];
      }
      return newValue;
    },
  },
  data: function () {
    return {
      relatorio: [],
      loadDebounce: null,
    };
  },
};
</script>